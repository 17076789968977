import {
  uploadFile,
  ossUploadFile
} from "../api/home";

//原生ios、安卓方法
export function calls(variableName, fnName) {
  var variable = window[variableName];
  if (!variable || !variable[fnName]) return;
  var params = [].slice.call(arguments, 2);
  variable[fnName](params);
};

// 原生定位获取
export function getLocation() {
  calls('MyJSInterface', 'workOrderTemplate', 'getLocation_', 'getLocation_call');
  calls('WebJSObject', 'workOrderTemplate', 'getLocation_', 'getLocation_call');
}

// 图片上传原生交互
export function getCalls(list) {
  if (list.length < 10) {
    let num = 10 - list.length;
    calls(
      "MyJSInterface",
      "workOrderTemplate",
      '{"name":"getImageUrls","num":' + num + "}",
      "getUpdateImageUrl_call"
    );
    calls(
      "WebJSObject",
      "workOrderTemplate",
      '{"name":"getImageUrls","num":' + num + ',"type":0}',
      "getUpdateImageUrl_call"
    );
  }
};

// 图片上传原生新交互
export function getNewCalls(list, maximum) {
  if (list.length < maximum) {
    let num = maximum - list.length;
    calls(
      "MyJSInterface",
      "workOrderTemplate",
      '{"name":"getImageUrls","num":' + num + '}',
      "getUpdateImageUrl_call"
    );
    calls(
      "WebJSObject",
      "workOrderTemplate",
      '{"name":"getImageUrls","num":' + num + ',"type":0}',
      "getUpdateImageUrl_call"
    );
  }
};

// 扫码原生交互
export function getScanCode() {
  calls(
    "MyJSInterface",
    "workOrderTemplate",
    '{"name":"getScanCode"}',
    "getScanCode_call"
  );
  calls(
    "WebJSObject",
    "workOrderTemplate",
    '{"name":"getScanCode"}',
    "getScanCode_call"
  );
}

// 图片上传公用
export function afterReadPubile(file, exImgs) {
  // 此时可以自行将文件上传至服务器
  if (file.length > 1) {
    file.forEach((e) => {
      let fileName = e.file.name;
      uploadFile().then((res) => {
        let formData = new FormData();
        formData.append("name", e.file.name);
        formData.append("key", `${res.dir}`);
        formData.append("policy", res.policy);
        formData.append("OSSAccessKeyId", res.accessid);
        formData.append("signature", res.signature);
        formData.append("success_action_status", "200");
        formData.append("file", e.file);
        ossUploadFile(formData);
        exImgs.push(res.accessUrl);
      });
    });
  } else {
    let fileName = file.file.name;
    uploadFile().then((res) => {
      let formData = new FormData();
      formData.append("name", file.file.name);
      formData.append("key", `${res.dir}`);
      formData.append("policy", res.policy);
      formData.append("OSSAccessKeyId", res.accessid);
      formData.append("signature", res.signature);
      formData.append("success_action_status", "200");
      formData.append("file", file.file);
      ossUploadFile(formData);
      exImgs.push(res.accessUrl);
    });
  }
}

// 图片删除公用
export function imgDeletePubile(detail, fileList, exImgs) {
  fileList.splice(detail.index, 1);
  exImgs.splice(detail.index, 1);
}